<template>
  <div style="margin-top: 20px;">
    <!-- 事件上报记录 -->
    <div class="box-card" style="text-align: left">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" ref="queryFormRef"
        @submit.native.prevent>
        <el-form-item label="日期：">
          <el-date-picker v-model="date" type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="请选择开始日期"
            end-placeholder="请选择结束日期" @blur="onSubmit">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select popper-class="my-select" clearable v-model="queryForm.appoint_status" placeholder="请选择任务状态"
            @clear="queryForm.appoint_status = null" @change="onSubmit()">
            <!-- <el-option label="全部" :value="3"></el-option> -->
            <el-option label="待指派" :value="1"></el-option>
            <el-option label="已指派" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="异常说明：">
          <el-input maxlength="30" v-model="queryForm.exception_note" @keyup.enter.native="onSubmit()"
            @input="(e) => (queryForm.exception_note = validSpace(e))" placeholder="请输入异常说明"></el-input>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="事件上报记录">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('3fb741ebb42544448ac3e777')">删除
            </lbButton>
            <lbButton type="setting" icon="shebeipeizhi" @click="openDefaultAppointDialog" v-if="this.getUserInfo.user_name != 'admin' &&
              isShowBtn('0c52974b2d074d9eb269c6c3')
              ">默认指派配置</lbButton>
            <lbButton icon="xinzeng" @click="openAppointDialog" v-if="this.getUserInfo.user_name != 'admin' &&
              isShowBtn('93b0d2f08c4b41dd8020012e')
              ">指派</lbButton>
            <!-- <el-button size="mini" icon="iconfont lebo-chakan" type="success" @click="$router.push('/look-event-record/id')">查看</el-button> -->
          </div>
        </template>
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="is_appoint">
              <span :style="row.is_appoint === 1 ? '' : 'color:#E6A23C;'">
                {{
                  row.is_appoint === 1
                  ? row.repairer_name
                  : "待指派"
                }}
              </span>
            </template>
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookEventRecord(row.Id)"></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
            <el-table-column prop="exception_note" label="异常说明"></el-table-column>
            <el-table-column prop="inspector_name" label="上报人员"></el-table-column>
            <el-table-column label="维修人员">
              <template slot-scope="scope">
                <span :style="scope.row.is_appoint === 1 ? '' : 'color:#E6A23C;'">
                  {{
                    scope.row.is_appoint === 1
                    ? scope.row.repairer_name
                    : "待指派"
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="上报时间" width="200">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookEventRecord(scope.row.Id)"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 默认指派维修人员 -->
    <lebo-dialog title="默认指派配置" :isShow="isDefaultAppointDialog" width="30%" class="appoint_dialog"
      @close="closeDefaultAppointDialog" footerSlot>
      <el-form :model="defaultAppointForm" :rules="defaultAppointFormRules" ref="defaultAppointFormRef"
        label-width="120px" class="demo-ruleForm">
        <el-form-item label="维修人员：" prop="repairer_id">
          <el-select popper-class="my-select" clearable v-model="defaultAppointForm.repairer_id" placeholder="请选择维修人员">
            <el-option v-for="j in inspectorSelectList" :key="j.Id" :label="j.user_name" :value="j.Id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isDefaultAppointDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="onSubmitDefaultAppoint()">确认指派</lbButton>
      </span>
    </lebo-dialog>
    <!-- 修改指派巡检人员 -->
    <lebo-dialog title="指派人员" :isShow="isAppointDialog" width="35%" class="appoint_dialog" @close="closeAppointDialog"
      footerSlot>
      <el-form :model="appointForm" :rules="appointFormRules" ref="appointFormRef" label-width="150px"
        class="demo-ruleForm" v-if="isEditAppoint">
        <el-form-item label="当前维修人员：">{{ appointForm.name }}
        </el-form-item>
        <el-form-item label="修改维修人员：" prop="repairer_id">
          <el-select popper-class="my-select" clearable v-model="appointForm.repairer_id" placeholder="请选择维修人员"
            @change="inspectorChange">
            <el-option v-for="j in inspectorSelectList" :key="j.Id" :label="j.user_name" :value="j.Id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" maxlength="100" v-model.trim="appointForm.remark" :rows="4" placeholder="请输入备注" @blur="
            appointForm.remark = appointForm.remark
              .replace(/\r\n|\n|\r/g, '<br/>')
              .replace(/\s/g, '')
              .replace(/<br\/>/g, '\n')
            ">
          </el-input>
        </el-form-item>
      </el-form>
      <el-form :model="appointForm" :rules="appointFormRules" ref="appointFormRef" label-width="120px"
        class="demo-ruleForm" v-else>
        <el-form-item label="维修人员：" prop="repairer_id">
          <el-select popper-class="my-select" clearable v-model="appointForm.repairer_id" placeholder="请选择维修人员"
            @change="inspectorChange">
            <el-option v-for="j in inspectorSelectList" :key="j.Id" :label="j.user_name" :value="j.Id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-dialog title="提示信息" :isShow="isMsgDialog" width="50%" @close="isMsgDialog = false" append-to-body footerSlot>
        <div v-if="promptMessage.data.length > 0">
          <div style="margin-bottom: 5px">{{ promptMessage.msg }}</div>
          <el-table ref="multipleTable" :data="promptMessage.data" tooltip-effect="dark" style="width: 100%" border
            stripe>
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="exception_note" label="异常说明"></el-table-column>
            <el-table-column label="失败原因">该问题已处理完成，不可再次指派。</el-table-column>
          </el-table>
        </div>
        <p v-else>{{ promptMessage.msg }}</p>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isAppointDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="onSubmitAppoint()">确认指派</lbButton>
      </span>
    </lebo-dialog>
    <!-- 查看时间上报记录 -->
    <lebo-dialog title='查看事件上报记录' :isShow="showLookEventRecord" @close="showLookEventRecord = false" footerSlot closeOnClickModal>
      <lookEventRecord v-if="showLookEventRecord" :id="dialogLookEventRecordId" @closeDialog="closeEventRecord">
      </lookEventRecord>
    </lebo-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import {
  getEventRecordList,
  setDefaultAppointEventRecord,
  getDefaultExceptionAppoint,
  getInspectorSelectList,
  setAppointEventRecord,
  delEventRecord
} from '@/api/assetsManagement'
import lookEventRecord from './components/lookEventRecord/index.vue'
export default {
  components: { lookEventRecord },
  data () {
    return {
      date: '',
      // 查询表单
      queryForm: {
        start_time: '',
        end_time: '',
        // 指派状态 1：未指派 2：已指派 3：全部
        appoint_status: null,
        exception_note: '',
        PageSize: 10,
        PageIndex: 1
      },
      // 数据列表
      tableData: [],
      total: 0,
      // 选中的数据
      multipleSelection: [],
      // 巡检人员下拉列
      inspectorSelectList: [],
      // 是否展示默认指派配置对话框
      isDefaultAppointDialog: false,
      defaultAppointForm: {
        repairer_id: '',
        // 默认指派类型 1：视频指派 2：事件上报指派
        eda_type: 2
      },
      defaultAppointFormRules: {
        repairer_id: [
          { required: true, message: '请选择巡检人员', trigger: 'blur' }
        ]
      },
      // 是否展示指派人员对话框
      isAppointDialog: false,
      // 是否是修改指派人员
      isEditAppoint: false,
      appointForm: {
        Id: '',
        name: '',
        repairer_id: '',
        repairer_name: '',
        remark: ''
      },
      appointFormRules: {
        repairer_id: [
          { required: true, message: '请选择维修人员', trigger: 'blur' }
        ],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      },
      // 指派完成的提示信息
      isMsgDialog: false,
      promptMessage: {
        msg: '',
        data: []
      },
      showLookEventRecord: false,
      dialogLookEventRecordId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '异常说明',
            prop: 'exception_note'
          }, {
            label: '上报人员',
            prop: 'inspector_name'
          }, {
            label: '维修人员',
            prop: 'repairer_name',
            slot: true
          }, {
            label: '上报时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetEventRecordList()
  },
  mounted () {
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getUserInfo', 'getOptions', 'getWayName'])
  },
  watch: {
    date (newV, oldV) {
      if (newV) {
        this.queryForm.start_time = newV[0]
        this.queryForm.end_time = newV[1]
      } else {
        this.queryForm.start_time = ''
        this.queryForm.end_time = ''
        this.onSubmit()
      }
    }
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取事件上报记录列表
    async fnGetEventRecordList () {
      const res = await getEventRecordList({
        start_time: this.queryForm.start_time,
        end_time: this.queryForm.end_time,
        // 指派状态 1：未指派 2：已指派 3：全部
        appoint_status: this.queryForm.appoint_status
          ? this.queryForm.appoint_status
          : 3,
        exception_note: this.queryForm.exception_note,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      // console.log('获取事件上报记录列表-------', res);
      if (res && res.Code === 200) {
        this.tableData = res.Data.DataList
        this.total = res.Data.TotalCount
      } else {
        this.tableData = []
        this.total = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetEventRecordList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetEventRecordList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetEventRecordList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetEventRecordList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetEventRecordList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // 选中的数据
    selectionChange (list) {
      this.multipleSelection = list
    },
    // 点击删除按钮
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm(
          '此操作将永久删除选中的记录，您确定要删除选中的记录吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(() => {
            var obj = {}
            var arr = []
            for (var i = 0; i < this.multipleSelection.length; i++) {
              arr.push(this.multipleSelection[i].Id)
              if (arr.length === this.multipleSelection.length) {
                obj.Id = arr.join(',')
                this.fnDelEventRecord(obj)
              }
            }
          })
          .catch(() => {
            this.$msg.info('已取消删除')
          })
      } else {
        this.$msg.warning('请选择要删除的记录！')
      }
    },
    async fnDelEventRecord (obj) {
      const res = await delEventRecord(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetEventRecordList()
    },
    // 展示默认指派巡检人员对话框
    openDefaultAppointDialog () {
      this.isDefaultAppointDialog = true
      this.fnGetInspectorSelectList()
      this.fnGetDefaultExceptionAppoint()
    },
    // 获取巡检人员下拉列
    async fnGetInspectorSelectList () {
      const person = await getInspectorSelectList({
        person_type: 2 // 资产人员类型 巡检人员 = 1, 维修人员 = 2
      })
      this.inspectorSelectList =
        person && person.Code === 200 ? person.Data : []
      var index = this.inspectorSelectList.findIndex(
        (item) => item.Id === this.defaultAppointForm.repairer_id
      )
      if (index < 0) {
        this.defaultAppointForm.repairer_id = ''
      }
      if (this.multipleSelection.length > 0 && this.isAppointDialog) {
        this.multipleSelection.forEach((item) => {
          if (item.is_appoint === 1) {
            // 已指派
            this.inspectorSelectList = this.inspectorSelectList.filter((j) => {
              return j.Id !== item.repairer_id
            })
          }
        })
      }
    },
    // 获取默认配置数据
    async fnGetDefaultExceptionAppoint () {
      const res = await getDefaultExceptionAppoint({
        eda_type: 2
      })
      this.defaultAppointForm.repairer_id =
        res && res.Code === 200 && res.Data ? res.Data.repairer_id : ''
    },
    // 保存默认指派配置
    onSubmitDefaultAppoint () {
      this.$refs.defaultAppointFormRef.validate((valid) => {
        if (valid) {
          this.fnSetDefaultAppointEventRecord(this.defaultAppointForm)
        } else {
          return false
        }
      })
    },
    // 默认指派配置接口
    async fnSetDefaultAppointEventRecord (obj) {
      const res = await setDefaultAppointEventRecord(obj)
      if (res && res.Code === 200) {
        this.isDefaultAppointDialog = false
      }
      this.fnGetEventRecordList()
    },
    // 默认维修人员配置对话框关闭时触发
    closeDefaultAppointDialog () {
      this.isDefaultAppointDialog = false
      this.$refs.defaultAppointFormRef.resetFields()
    },
    // 展示指派巡检人员对话框
    openAppointDialog () {
      if (this.multipleSelection.length > 0) {
        this.isAppointDialog = true
        this.fnGetInspectorSelectList()
        var nameArr = []
        var idArr = []
        this.multipleSelection.forEach((item) => {
          if (item.is_appoint === 1) {
            // 已指派
            nameArr.push(item.repairer_name)
          }
          idArr.push(item.Id)
        })
        var that = this
        this.$nextTick(() => {
          this.appointForm.Id = idArr.join(',')
          this.appointForm.name = [...new Set(nameArr)].join('、')
        })
        this.isEditAppoint = nameArr.length > 0
      } else {
        this.$msg.warning('请选择要指派的任务！')
      }
    },
    // 巡检人员下拉列发生改变时触发
    inspectorChange (val) {
      console.log(val)
      var arr = this.inspectorSelectList.filter((item) => {
        return item.Id === val
      })
      this.appointForm.repairer_name = arr[0].user_name
    },
    // 保存指派人员配置
    onSubmitAppoint () {
      this.$refs.appointFormRef.validate((valid) => {
        if (valid) {
          this.fnSetAppointEventRecord({
            Id: this.appointForm.Id,
            repairer_id: this.appointForm.repairer_id,
            repairer_name: this.appointForm.repairer_name,
            remark: this.appointForm.remark
          })
        } else {
          return false
        }
      })
    },
    // 指派任务请求
    async fnSetAppointEventRecord (obj) {
      const res = await setAppointEventRecord(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        if (this.isEditAppoint) {
          this.isMsgDialog = true
          this.promptMessage.msg = res.Message
          this.promptMessage.data = res.Data
        } else {
          this.isAppointDialog = false
          this.fnGetEventRecordList()
        }
      }
    },
    // 关闭指派维修人员对话框时触发
    closeAppointDialog () {
      this.isAppointDialog = false
      this.$refs.appointFormRef.resetFields()
      this.appointForm.Id = ''
      this.appointForm.repairer_name = ''
      this.appointForm.name = ''
    },
    // 关闭对话框
    closeMsgDialog () {
      this.isAppointDialog = false
      this.isEditAppoint = false
      this.isMsgDialog = false
      this.fnGetEventRecordList()
    },
    closeEventRecord () {
      this.showLookEventRecord = false
      this.fnGetEventRecordList()
    },
    lookEventRecord (id) {
      this.dialogLookEventRecordId = id
      this.showLookEventRecord = true
    }
  }
}
</script>

<style scoped lang="less">
.appoint_dialog {
  /deep/ .el-dialog__body {
    padding: 30px;

    .el-textarea,
    .el-select {
      width: 100%;
    }
  }
}
</style>
